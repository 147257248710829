import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedSession, currentUser, showSessionChat, guestsForSession, guestConnections } from '../recoil/atoms'
import { clientsForSession, guestsForSessionRewards, videoConnections, techCheckConnections, clientConnections } from '../recoil/selectors'
import { useLocalStorage } from '../hooks/hooks'
import { IconButton, Divider, Modal, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack'
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
// import MaterialTable from "material-table"
// import AddFab from '../components/AddFab';
import SessionModal from '../components/SessionModal';
// import SessionTableDetails from '../components/SessionTableDetails';
import SessionSubscription from '../recoil/SessionSubscription';
import { withSuspense } from '../hooks/suspense'
import {
  sendCustomEmailPromise,
  sendScheduleLinkPromise,
  sendGuestLinkPromise,
  sendVerifyLinkPromise,
  checkInGuestPromise,
  techCheckGuestPromise,
  approveGuestPromise,
  waitlistGuestPromise,
  denyGuestPromise,
  resetGuestPromise,
  cancelGuestPromise,
  deleteGuestPromise,
  uploadGuestCSVPromse,
  uncheckInGuestPromise,
  banGuestPromise,
  getGuestPhotoPromise,
  uploadGuestPhotoPromise,
  batchUpdateGuestPromise,
  removeFromEmailDenylistPromise,
  zoomRegisterPromise,
  moveGuestToNewSessionPromise,
} from '../services/guestsService'
import { getChatCredentialPromise } from '../services/chimeService'
import { insertGuestPromise, updateGuestPromise } from '../services/guestsService';
import { useOktaAuth } from '@okta/okta-react';
import useWebSocket from 'react-use-websocket';
import moment from 'moment-timezone'
import GuestsSubscription from '../recoil/GuestsSubscription';
import LoadingPage from './LoadingPage'
import SessionDetailsHeader from '../components/SessionDetailsHeader';
import SessionUsersView from '../components/SessionUsersView';
import SessionVideoView from '../components/SessionVideoView';
import GuestModal from '../components/GuestModal';
import GuestSpeedDial from '../components/GuestSpeedDial';
import ChatDrawer from '../components/chime/ChatDrawer';
import config from '../config.json'
import SessionBalanceView from '../components/SessionBalanceView';
import SessionTechCheckView from '../components/SessionTechCheckView';
import { getPFMScreeningsPromise } from '../services/recruitService';
import { getSurveysPromise } from '../services/surveyService';
import { getAcuityAppointmentTypes, getAcuityCalendars } from '../services/appointmentService';
import { getVideosPromise, getYoutubeVideoPromise, getIVSVideoPromise, getIVSChannelsPromise } from '../services/videoService'
import { getUsersPromise, getUserMeetingsPromise } from '../services/zoomService'
import { getCustomersPromise, getCatalogsPromise, getEmailTemplatesPromise } from '../services/tangoService'
import { updateSessionPromise, uploadSessionFilePromise, getSessionFilePromise, getAllSessionFilesPromise, deleteSessionFilePromise } from '../services/sessionsService'
import { CsvBuilder } from 'filefy'
import { useClipboard } from 'use-clipboard-copy';
import SessionClientView from '../components/SessionClientView';
import ChimeChatUsersModal from '../components/ChimeChatUsersModal';
import { getProjectsPromise, getProjectSessionsPromise } from '../services/projectsService';
import SessionRewardsView from '../components/SessionRewardsView';
import { insertRewardsPromise, updateRewardsPromise, updateGuestRewardsPromise, submitRewardOrderPromise, resendGuestRewardPromise } from '../services/rewardsService';
import { exportDecipherScript } from '../utils/decipherGenerator';
import StandardDialog from '../components/standard/StandardDialog';
import translations from "../utils/translations.json";
import { useNavigate, useParams } from 'react-router-dom';

const PREFIX = 'SessionManagementPage';

const classes = {
  sessions: `${PREFIX}-sessions`,
  textRow: `${PREFIX}-textRow`,
  titlegroup: `${PREFIX}-titlegroup`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  page: `${PREFIX}-page`,
  footer: `${PREFIX}-footer`,
  video: `${PREFIX}-video`,
  removeEmailBtn: `${PREFIX}-removeEmailBtn`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.sessions}`]: {
    marginBottom: '1rem',
    width: '100%'
  },
  [`& .${classes.textRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  [`& .${classes.titlegroup}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '8px'
  },
  [`& .${classes.content}`]: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    minHeight: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
  },
  [`& .${classes.page}`]: {
    // height: '100%', // USING ALIGN ITEMS STRETCH
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  [`& .${classes.footer}`]: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
  [`& .${classes.video}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  [`& .${classes.removeEmailBtn}`]: {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

const JSZip = require('jszip')

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2, excluded = []) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.filter(k => !excluded.includes(k)).reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const mandrillErrors = {
  'hard_bounce': 'Hard Bounce',
  'soft_bounce': 'Soft Bounce',
  'spam': "Spam",
  'unsub': "Unsubscribed",
  'custom': "Email on Denylist",
  'invalid': "Invalid",
  'invalid-sender': "Invalid Sender"
}

const DismissBtn = (id) => {
  const { closeSnackbar } = useSnackbar();
  return <>
    <IconButton
      aria-label="close"
      color="inherit"
      // className={classes.close} //NO CLASS DEFINED
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon />
    </IconButton>
  </>
}

const SessionManagementPage = React.memo(() => {
  const navigate = useNavigate();
  const { sessionId, selectedView = 'guests' } = useParams();
  const theme = useTheme();

  // console.log('render page', selectedView)
  const session = useRecoilValue(selectedSession);
  const guests = useRecoilValue(guestsForSession(sessionId));
  const clients = useRecoilValue(clientsForSession(sessionId));
  const rewardGuests = useRecoilValue(guestsForSessionRewards(sessionId));
  const [connections, setConnections] = useRecoilState(guestConnections(sessionId))
  // const setConnections = useSetRecoilState(guestConnections(sessionId))
  const vConnections = useRecoilValue(videoConnections(sessionId))
  const tcConnections = useRecoilValue(techCheckConnections(sessionId))
  const clConnections = useRecoilValue(clientConnections(sessionId))
  const user = useRecoilValue(currentUser);
  const [showChat, setShowChat] = useRecoilState(showSessionChat(sessionId))
  // const [tableGuests, setTableGuests] = useState(undefined)

  // const [tab, setTab] = useState(selectedView || 'guests');
  const [sessionModalOpen, setSessionModalOpen] = useLocalStorage(`sessionManagement${sessionId}ModalState`, false) //useState(false);
  const [guestSelected, setGuestSelected] = useLocalStorage(`session${sessionId}guestSelected`, null)//useState();
  const [guestModalOpen, setGuestModalOpen] = useLocalStorage(`session${sessionId}guestModalState`, false)
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState({});
  const [guestLoading, setGuestLoading] = useState(false);
  const [batchLoading, setBatchLoading] = useState(false);
  const [zoomLoading, setZoomLoading] = useState(false);
  const [guestApprove, setGuestApprove] = useState(new Set())
  const [guestWaitlist, setGuestWaitlist] = useState(new Set())
  const [guestDeny, setGuestDeny] = useState(new Set())
  const [guestReset, setGuestReset] = useState(new Set())
  const [guestCheckIn, setGuestCheckIn] = useState(new Set())
  const [guestSendEmail, setGuestSendEmail] = useState(new Set())
  const [guestUncheckIn, setGuestUncheckIn] = useState(new Set())
  const [guestTurnAway, setGuestTurnAway] = useState(new Set())
  const [guestCanceled, setGuestCanceled] = useState(new Set());
  const [guestRewardResend, setGuestRewardResend] = useState(new Set());
  // const [guestFocusGroup, setGuestFocusGroup] = useState(new Set())
  const [guestUpdate, setGuestUpdate] = useState({});
  const [rewardsUpdate, setRewardsUpdate] = useState({});
  // const [error, setError] = useState();
  // const [errorOpen, setErrorOpen] = useState(false)
  // const [hideErrorManually, setHideErrorManually] = useState(false)
  const [completeWarnOpen, setCompleteWarnOpen] = useState(false)
  const [sessionStarted, setSessionStarted] = useState(false)
  const [startTime, setStartTime] = useLocalStorage(`session${sessionId}starttime`, null)
  const [videoIndex, setVideoIndex] = useState()
  const [videoComplete, setVideoComplete] = useState(false)
  const [liveStart, setLiveStart] = useState(false)
  const [linksrAnchorEl, setLinksAnchorEl] = useState(null);
  const [filesrAnchorEl, setFilesAnchorEl] = useState(null);
  const [zoomAliases, setZoomAliases] = useState([]);
  const [chatDisabled, setChatDisabled] = useState(false);
  const [chimeChannelUserData, setChimeChannelUserData] = useState()
  const [chatNotification, setChatNotification] = useState(0)
  const [sessionInfo, setSessionInfo] = useState(null)
  const { oktaAuth } = useOktaAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const clipboard = useClipboard({
    copiedTimeout: 1500
  });

  const sessionRef = useRef(sessionId)
  const sendMessageRef = useRef()
  const guestBatchRef = useRef([])
  const playerRef = useRef()
  const updateUserRef = useRef()
  const messageUserRef = useRef()
  const ivsResponseRef = useRef();
  const timeUpdateRef = useRef();
  const videoPlatformRef = useRef();

  useEffect(() => {
    if (session) {
      // Update session data
      if (!session.isDialSession) {
        let data = {
          ...session,
          scoreBase: 100,
          dialStyle: "Embedded Slider",
          dialMotion: "Stepwise",
          dialSnap: false,
          gaugeStyle: "With Shapes",
          sliderKnob: "Clapperboard",
          sliderKnobColor: "#F44E3B",
          numberOfMeters: 19,
          noDialArrowKeys: false,
          includeTuneOut: true,
          limitTuneOut: false,
          startTuneOutTime: 2,
          stopTuneOutTime: 2,
          askTuneOutReason: false,
          maxReasonLength: 140,
          maxReasonTime: 45,
          inactiveReminder: true,
          inactiveInterval: 120,
          inactiveMessage: session.viewerLanguage ? translations.continueToMoveDial[session.viewerLanguage] : 'Please continue to move your dial',
          clientDialViewer: true,
        }
        // Update session
        setSessionInfo(data)
        videoPlatformRef.current = data.videoPlatform
      } else {
        setSessionInfo(session)
        videoPlatformRef.current = session.videoPlatform
      }
    }
  }, [session])

  const socketUrl = `wss://${config.ws.videoAdminWS}?sessionId=${sessionId}`
  const getSocketUrl = useCallback(() => {
    const token = oktaAuth.getAccessToken()
    return `${socketUrl}&access_token=${token}`
  }, [oktaAuth, socketUrl])
  const STATIC_OPTIONS = useMemo(() => ({
    onOpen: () => {
      const message = {
        action: 'connection',
        sessionId: sessionRef.current,
        adminTime: Date.now()
      }
      sendMessageRef.current(JSON.stringify(message));
    },
    shouldReconnect: (closeEvent) => true,
    // onClose: () => {
    //   const message = {
    //     action: 'leave',
    //     sessionId: sessionRef.current,
    //     // clientTime: Date.now()
    //   }
    //   sendMessageRef.current(JSON.stringify(message));
    // }
  }), []);
  // const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage } = useWebSocket(getSocketUrl, STATIC_OPTIONS);
  sendMessageRef.current = sendMessage;

  useEffect(() => {
    // if (!user.fullAdmin && !user.assignedSessions.map(x => `${x}`).includes(sessionId)) {
    //   navigate('/')
    // } else 
    if ((selectedView === 'balance' && !user.accessLevels.includes('balance')) || (selectedView === 'techcheck' && !user.accessLevels.includes('tc')) || (selectedView === 'dial' && !(user.accessLevels.includes('dial') || user.accessLevels.includes('client'))) || (selectedView === 'rewards' && !user.fullAdmin)) {
      navigate(`/session/${sessionId}/guests`)
    }
  }, [user, selectedView, sessionId, navigate])

  useEffect(() => {
    if (session) {
      if (!session.id) {
        navigate('/')
      } else if (!session.clientDialViewer && selectedView === 'dial') {
        navigate(`/session/${session.id}/video`)
      } else if (selectedView === 'rewards' && !(session.started && session.complete)) {
        navigate(`/session/${session.id}/guests`)
      }
    }
  }, [session, selectedView, navigate])

  useEffect(() => {
    if (guests) {
      const aliasList = guests.map(guest => guest.alias);
      setZoomAliases(aliasList)
    }
  }, [guests])

  useEffect(() => {
    if (lastMessage !== null && lastMessage.data) {
      const message = JSON.parse(lastMessage.data)
      console.log('received message', message)
      if (message.guests) {
        guestBatchRef.current = { ...guestBatchRef.current, ...message.guests }
        const more = message.more;
        if (!more) {
          setConnections(guestBatchRef.current)
        }
      } else if (message.connections) {
        setConnections((prev) => {
          const updated = { ...prev }
          message.connections.forEach((connection) => {
            updated[connection.userId] = connection
          })
          return updated
        })
      } else if (message.messageType === 'startSession' && !message.guestId) {
        setSessionStarted(true)
        setStartTime(message.sessionStartTime)
        setVideoIndex(message.videoIndex || 0)
        setUpdate({})
      } else if (message.messageType === 'stopSession' || message.messageType === 'finished') {
        setVideoComplete(true)
        setUpdate({})
      } else if (message.messageType === 'liveStart') {
        setLiveStart(true)
        // setUpdate({})
      } else if (message.messageType === 'resetSession') {
        setSessionStarted(false)
        setStartTime()
        setVideoIndex(0)
        setUpdate({})
      } else if (message.messageType === 'csvImport') {
        enqueueSnackbar(message.successful ? 'Import Guests from CSV Complete' : typeof message.error === 'string' ? `Error Importing Guests - ${message.error}` : 'Error Importing Guests from CSV', { preventDuplicate: true, action: DismissBtn });
        // setError(message.successful ? 'Import Guests from CSV Complete' : typeof message.error === 'string' ? `Error Importing Guests - ${message.error}` : 'Error Importing Guests from CSV')
        setGuestUpdate({})
      } else if (message.messageType === 'updateGuests') {
        setGuestUpdate({})
      } else if (message.messageType === 'updateSession') {
        setUpdate({})
      } else if (message.messageType === 'updateRewards') {
        setRewardsUpdate(message)
      } else if (message.messageType === 'rewardsProcessed') {
        enqueueSnackbar(message.successful ? 'Tango Reward Order Processing Complete' : 'Error Processing Tango Reward Order', { preventDuplicate: true, action: DismissBtn });
        // setError(message.successful ? 'Tango Reward Order Processing Complete' : 'Error Processing Tango Reward Order')
        setRewardsUpdate({})
      }
    }
  }, [lastMessage, setConnections, enqueueSnackbar])


  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  // const handleErrorClose = useCallback(() => {
  //   console.log("called------")
  //   setErrorOpen(false)
  //   setHideErrorManually(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  //   setHideErrorManually(false)
  // }, [])

  const updateTimeRef = useCallback((ts) => {
    timeUpdateRef.current = ts;
  }, [])

  const handleLinksMenuClose = useCallback(() => {
    setLinksAnchorEl(null);
  }, []);

  const handleFilesMenuClose = useCallback(() => {
    setFilesAnchorEl(null);
  }, []);

  const handleViewChange = useCallback((event, newView) => {
    if (newView !== null) {
      navigate(`/session/${sessionId}/${newView}`)
    }
  }, [navigate, sessionId]);

  const handleOpenLink = useCallback((event) => {
    event.currentTarget.blur()
    setLinksAnchorEl(event.currentTarget);
  }, []);

  const handleOpenFile = useCallback((event) => {
    event.currentTarget.blur();
    setFilesAnchorEl(event.currentTarget);
  }, []);

  const openCompleteWarning = useCallback(() => {
    setCompleteWarnOpen(true)
  }, [])

  const handleCompleteSession = useCallback(() => {
    const message = {
      action: 'completeSession',
      sessionId: sessionRef.current
    }
    sendMessageRef.current(JSON.stringify(message));
    setCompleteWarnOpen(false)
  }, []);

  const handleStartVideo = useCallback((guestId) => {
    const message = {
      action: 'startSession',
      sessionId: sessionRef.current,
      guestId,
      techCheck: !!guestId
      // adminTime: Date.now()
    }
    if (!guestId && session?.isLiveSession && playerRef.current) {
      if(videoPlatformRef.current === 'IVS') message.videoTime = (Math.abs(Date.parse(ivsResponseRef.current.startTime) - timeUpdateRef.current)) / 1000
      else message.videoTime = playerRef.current.getCurrentTime?.()  
    } else if (!guestId && session?.multiSection) {
      message.videoIndex = (session.started || sessionStarted) ? (videoIndex || 0) + 1 : 0
    }
    sendMessageRef.current(JSON.stringify(message));
  }, [session, sessionStarted, videoIndex, sessionInfo])

  const handleStopSession = useCallback(() => {
    const message = {
      action: 'stopSession',
      sessionId: sessionRef.current,
      complete: !session?.hasSurvey && session?.surveyPlacement !== 'pre' && session?.type !== 'Focus Group',
      isFocusGroup: session?.type === 'Focus Group',
      // adminTime: Date.now()
    }
    if (playerRef.current) message.videoTime = session?.isLiveSession ? playerRef.current.getCurrentTime?.() : playerRef.current.currentTime?.()
    sendMessageRef.current(JSON.stringify(message));
  }, [session]);

  const handleLiveStart = useCallback(() => {
    const message = {
      action: 'liveStart',
      sessionId: sessionRef.current,
      // adminTime: Date.now()
    }
    if (playerRef.current) message.videoTime = playerRef.current.getCurrentTime?.()
    if(videoPlatformRef.current === 'IVS'){
      message.videoTime = (Math.abs(Date.parse(ivsResponseRef.current.startTime) - timeUpdateRef.current)) / 1000
    }
    sendMessageRef.current(JSON.stringify(message));
  }, []);

  const handleResetVideo = useCallback(() => {
    const message = {
      action: 'resetSession',
      sessionId: sessionRef.current,
      // adminTime: Date.now()
    }
    sendMessageRef.current(JSON.stringify(message));
  }, [])

  const removeFromEmailDenylist = useCallback((email) => {
    const token = oktaAuth.getAccessToken()
    removeFromEmailDenylistPromise(email, token).then((res) => {
      if (res.mandrill.deleted) {
        // deleted successfully
        closeSnackbar(email);
        enqueueSnackbar(`Email ${email} has been removed from the denylist`, { variant: 'success', action: DismissBtn })
      }
    }).catch(error => {
      console.log('Error removing email from denylist:', error);
    })
  }, [oktaAuth, closeSnackbar, enqueueSnackbar])

  const removeEmailBtn = useCallback((id) => {
    return <>
      <button className={classes.removeEmailBtn} onClick={() => removeFromEmailDenylist(id)}>
        Remove
      </button>
      <IconButton
        aria-label="close"
        color="inherit"
        className={classes.close}
        onClick={() => closeSnackbar(id)}
      >
        <CloseIcon />
      </IconButton>
    </>
  }, [classes, removeFromEmailDenylist, closeSnackbar]);

  const checkInGuest = useCallback((event, row) => {
    setGuestCheckIn(prev => new Set([...prev, row.id]))
    // const expiration = moment(session.scheduledStartTime).diff(moment()) + (session.videoLength ? Math.ceil(session.videoLength / 1000) + 900 : 12600)
    // console.log('expiring in seconds: ', expiration)
    const linkLabel = `${row.role === 'Client' ? 'Client' : 'Guest'} Link`
    // const noEmail = !!(session.type === 'Focus Group' && !session.hasSurvey)
    const token = oktaAuth.getAccessToken()
    let errType, errEmail;
    checkInGuestPromise([row.id], session.id, false, token).then((res) => {

      setGuestUpdate({})
      let isFGSuppressed;
      if (res.mandrill[0].status === 'invalid' || res.mandrill[0].status === 'rejected') {
        errType = res.mandrill[0].reject_reason;
        if (mandrillErrors[errType]) {
          errEmail = res.mandrill[0].email;
          throw { message: `${mandrillErrors[errType]} ${errType === "custom" ? '(' + errEmail + ')' : ''}` }
        }
        else throw errType
      } else if (res.mandrill[0].status === 'FG_suppressed') {
        isFGSuppressed = true;
      }
      setGuestCheckIn(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      });
      // TODO: Update setError to non error method
      // setError(`${linkLabel} Sucessfully Sent`);
      enqueueSnackbar(isFGSuppressed ? `Check In Successful` : `${linkLabel} Sucessfully Sent`, { preventDuplicate: true, action: DismissBtn })
      clipboard.copy(row.alias);
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestCheckIn(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        if (errType === "custom") {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : `Error Emailing ${linkLabel}`, { action: removeEmailBtn, persist: true, variant: 'error', key: errEmail })
        } else {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : `Error Emailing ${linkLabel}`, { action: DismissBtn, persist: true, variant: 'error' })
        }
          // setError(error.message ? `Mandrill Error - ${error.message}` : `Error Emailing ${linkLabel}`)

        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [clipboard, session, oktaAuth, removeEmailBtn, enqueueSnackbar])

  const techCheckGuest = useCallback((event, row) => {
    setGuestCheckIn(prev => new Set([...prev, row.id]))
    const expiration = 3600 //one hour for techcheck
    const token = oktaAuth.getAccessToken()
    let errType, errEmail;
    techCheckGuestPromise(row.id, expiration, token).then((res) => {
      setGuestUpdate({})
      if (res.mandrill[0].status === 'invalid' || res.mandrill[0].status === 'rejected') {
        errType = res.mandrill[0].reject_reason;
        if (mandrillErrors[errType]) {
          errEmail = res.mandrill[0].email;
          throw { message: `${mandrillErrors[errType]} ${errType === "custom" ? '(' + errEmail + ')' : ''}` }
        }
        else throw errType
      }
      setGuestCheckIn(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError('Tech Check Link Sucessfully Sent')
      enqueueSnackbar(`Tech Check Link Sucessfully Sent`, { preventDuplicate: true, action: DismissBtn })
    }).catch(error => {
      console.log('err', error)
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestCheckIn(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError(error.message ? `Mandrill Error - ${error.message}` : 'Error Emailing Tech Check Link')
        if (errType === "custom") {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : 'Error Emailing Tech Check Link', { action: removeEmailBtn, persist: true, variant: 'error', key: errEmail })
        } else {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : 'Error Emailing Tech Check Link', { action: DismissBtn, persist: true, variant: 'error' })
        }
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [oktaAuth, removeEmailBtn, enqueueSnackbar])

  const approveGuest = useCallback((event, row) => {
    // const guestData = guests.map((guest) => {
    //   return {
    //     id: guest.id,
    //   }
    // })
    setGuestApprove(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    approveGuestPromise(session.id, row.id, token).then((res) => {
      setGuestApprove(prev => {
        const data = new Set([...prev])
        // guestData.forEach(row => data.delete(row.id))
        data.delete(row.id)
        return data
      })
      // setError('Guest Sucessfully Approved')
      enqueueSnackbar('Guest Successfully Approved', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestApprove(prev => {
          const data = new Set([...prev])
          // guestData.forEach(row => data.delete(row.id))
          data.delete(row.id)
          return data
        })
        // setError(error.message)
        enqueueSnackbar(error.message, { preventDuplicate: true, action: DismissBtn });
        setGuestUpdate({})
      }
    })
  }, [session, oktaAuth, enqueueSnackbar])

  const waitlistGuest = useCallback((event, row) => {
    // const guestData = guests.map((guest) => {
    //   return {
    //     id: guest.id,
    //   }
    // })
    setGuestWaitlist(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    waitlistGuestPromise(session.id, row.id, token).then((res) => {
      setGuestWaitlist(prev => {
        const data = new Set([...prev])
        // guestData.forEach(row => data.delete(row.id))
        data.delete(row.id)
        return data
      })
      // setError('Guest Sucessfully Waitlisted')
      enqueueSnackbar('Guest Successfully Waitlisted', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestWaitlist(prev => {
          const data = new Set([...prev])
          // guestData.forEach(row => data.delete(row.id))
          data.delete(row.id)
          return data
        })
        // setError('Error Waitlisting Guest')
        let msg = error.message ? error.message : 'Error Waitlisting Guest';
        enqueueSnackbar(msg, { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [session, oktaAuth, enqueueSnackbar])

  const denyGuest = useCallback((event, row) => {
    // const guestData = guests.map((guest) => {
    //   return {
    //     id: guest.id,
    //   }
    // })
    setGuestDeny(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    denyGuestPromise(session.id, row.id, token).then((res) => {
      setGuestDeny(prev => {
        const data = new Set([...prev])
        // guestData.forEach(row => data.delete(row.id))
        data.delete(row.id)
        return data
      })
      // setError('Guest Sucessfully Denied')
      enqueueSnackbar('Guest Successfully Denied', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestDeny(prev => {
          const data = new Set([...prev])
          // guestData.forEach(row => data.delete(row.id))
          data.delete(row.id)
          return data
        })
        // setError('Error Denying Guest')
        enqueueSnackbar('Error Denying Guest', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [session, oktaAuth, enqueueSnackbar])

  const resetGuest = useCallback((event, row) => {
    // const guestData = guests.map((guest) => {
    //   return {
    //     id: guest.id,
    //   }
    // })
    setGuestReset(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    resetGuestPromise(session.id, row.id, token).then((res) => {
      setGuestReset(prev => {
        const data = new Set([...prev])
        // guestData.forEach(row => data.delete(row.id))
        data.delete(row.id)
        return data
      })
      // setError('Guest Sucessfully Reset')
      enqueueSnackbar('Guest Successfully Reset', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestReset(prev => {
          const data = new Set([...prev])
          // guestData.forEach(row => data.delete(row.id))
          data.delete(row.id)
          return data
        })
        // setError('Error Resetting Guest')
        enqueueSnackbar('Error Resetting Guest', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [session, oktaAuth, enqueueSnackbar])

  const uncheckInGuest = useCallback((event, row, isNonInteractiveFG) => {
    if(!isNonInteractiveFG) setGuestUncheckIn(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    uncheckInGuestPromise(row.id, token).then((res) => {
      // setGuestUpdate({})
      // if (res.mandrill[0].status === 'invalid' || res.mandrill[0].status === 'rejected') throw new Error('Mandrill Error')
      setGuestUncheckIn(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError('Guest Sucessfully Unchecked-In')
      enqueueSnackbar('Guest Successfully Unchecked-In', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestUncheckIn(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError('Error Unchecking-In Guest')
        enqueueSnackbar('Error Unchecking-In Guest', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        // setGuestUpdate({})
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const emailRegistrationLink = useCallback((event, row) => {
    setGuestSendEmail(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    let errType, errEmail;
    sendScheduleLinkPromise(session.id, [row.id], token).then((res) => {
      // setUpdate({})
      if (res.mandrill[0].status === 'invalid' || res.mandrill[0].status === 'rejected') {
        errType = res.mandrill[0].reject_reason;
        if (mandrillErrors[errType]) {
          errEmail = res.mandrill[0].email;
          throw { message: `${mandrillErrors[errType]} ${errType === "custom" ? '(' + errEmail + ')' : ''}` }
        }
        else throw errType
      }
      setGuestSendEmail(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError('Registration Link Sucessfully Sent')
      enqueueSnackbar(`Registration Link Sucessfully Sent`, { preventDuplicate: true, action: DismissBtn })
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestSendEmail(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError(error.message ? `Mandrill Error - ${error.message}` : 'Error Emailing Registration Link')
        if (errType === "custom") {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : 'Error Emailing Registration Link', { action: removeEmailBtn, persist: true, variant: 'error', key: errEmail })
        } else {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : 'Error Emailing Registration Link', { action: DismissBtn, persist: true, variant: 'error' })
        }
        // setHideErrorManually(true)
        // setUpdate({})
      }
    })
  }, [session, oktaAuth, removeEmailBtn, enqueueSnackbar])

  const emailGuestLink = useCallback((event, row) => {
    console.log('EMAIL GUEST LINK', event, row)
    setGuestSendEmail(prev => new Set([...prev, row.id]))
    console.log(row.state, row.emailVerified, row.autoDenied)
    const sendPromise = (session.autoTechCheck && !session.bypassTechCheck && row.state === 'Registered' && !row.acuityAppointmentId) ? sendVerifyLinkPromise : sendGuestLinkPromise
    const linkLabel = `${row.role === 'Client' ? 'Client' : 'Guest'} Link`
    const token = oktaAuth.getAccessToken()
    let errType, errEmail;
    sendPromise(session.id, [row.id], token).then((res) => {
      // setUpdate({})
      if (res.mandrill[0].status === 'invalid' || res.mandrill[0].status === 'rejected') {
        errType = res.mandrill[0].reject_reason;
        if (mandrillErrors[errType]) {
          errEmail = res.mandrill[0].email;
          throw { message: `${mandrillErrors[errType]} ${errType === "custom" ? '(' + errEmail + ')' : ''}` }
        }
        else throw errType
      }
      setGuestSendEmail(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError(`${linkLabel} Sucessfully Sent`)
      enqueueSnackbar(`${linkLabel} Sucessfully Sent`, { preventDuplicate: true, action: DismissBtn })
      console.log("Here-------")
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestSendEmail(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError(error.message ? `Mandrill Error - ${error.message}` : `Error Emailing ${linkLabel}`)
        if (errType === "custom") {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : `Error Emailing ${linkLabel}`, { action: removeEmailBtn, persist: true, variant: 'error', key: errEmail })
        } else {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : `Error Emailing ${linkLabel}`, { action: DismissBtn, persist: true, variant: 'error' })
        }
        // setHideErrorManually(true)
        // setUpdate({})
      }
    })
  }, [session, oktaAuth, removeEmailBtn, enqueueSnackbar])

  const sendBatchEmails = useCallback(({ type, subject, content, includeLogo, includeTitle, includeTime, emailTitle }, guestIds) => {
    setBatchLoading(true)
    setGuestSendEmail(prev => new Set([...prev, ...guestIds]))
    const sendPromise = type === 'verify' ? sendVerifyLinkPromise : type === 'event' ? sendGuestLinkPromise : type === 'schedule' ? sendScheduleLinkPromise : sendCustomEmailPromise
    const token = oktaAuth.getAccessToken()
    const customEmailParams = type === 'custom' ? [subject, content, { includeLogo, includeTitle, includeTime, emailTitle }] : [];
    var errors = [];
    sendPromise(session.id, guestIds, ...customEmailParams, token).then((res) => {
      res.mandrill.forEach(r => {
        if (r.status === 'invalid' || r.status === 'rejected') {
          let errObj = {
            errType: r.reject_reason,
            errEmail: r.email
          }
          if (mandrillErrors[errObj.errType]) {
            errObj.message = `${mandrillErrors[errObj.errType]} ${errObj.errType === "custom" ? '(' + errObj.errEmail + ')' : ''}`;
            errors.push(errObj)
            // throw 
          }
          else errors.push(errObj);
        }
      });

      if (errors.length !== res.mandrill.length) {
        // setError(`Emails Sucessfully Sent`)
        enqueueSnackbar(`Emails Sucessfully Sent`, { preventDuplicate: true, action: DismissBtn })
      }

      if (errors.length > 0) {
        throw errors;
      }

      setGuestSendEmail(prev => {
        const data = new Set([...prev])
        guestIds.forEach(gid => data.delete(gid))
        return data
      })
      setBatchLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError(error.message ? `Mandrill Error - ${error.message}` : `Error Sending Batch Emails`)
        if (Array.isArray(error)) {
          error.forEach(e => {
            if (e.errType === "custom") {
              enqueueSnackbar(e.message ? `Mandrill Error - ${e.message}` : `Error Sending Batch Emails`, { action: removeEmailBtn, persist: true, variant: 'error', key: e.errEmail })
            } else {
              enqueueSnackbar(e.message ? `Mandrill Error - ${e.message}` : `Error Sending Batch Emails`, { action: DismissBtn, persist: true, variant: 'error' })
            }
          })
        } else {
          enqueueSnackbar(error.message ? `Mandrill Error - ${error.message}` : `Error Sending Batch Emails`, { action: DismissBtn, persist: true, variant: 'error' })
        }


        // setHideErrorManually(true)
        setGuestSendEmail(prev => {
          const data = new Set([...prev])
          guestIds.forEach(gid => data.delete(gid))
          return data
        })
        setBatchLoading(false)
      }
    })
  }, [session, oktaAuth, removeEmailBtn, enqueueSnackbar])

  const handleCloseGuestDialog = useCallback(() => {
    // clearLocalStorage();
    setGuestModalOpen(false);
    setGuestSelected(null);
  }, [setGuestModalOpen, setGuestSelected]);

  const addGuest = useCallback(() => {
    setGuestSelected(null);
    setGuestModalOpen(true);
  }, [setGuestModalOpen, setGuestSelected])

  const editGuest = useCallback((event, data) => {
    // const rowData = cloneDeep(data)
    // delete rowData.tableData // REMOVE TABLE DATA PROP
    setGuestSelected(data);
    setGuestModalOpen(true);
  }, [setGuestModalOpen, setGuestSelected])

  const deleteGuest = useCallback((event, { id, sessionId, tableData }) => {
    setGuestLoading(true)
    const token = oktaAuth.getAccessToken()
    deleteGuestPromise(id, sessionId, token).then((res) => {
      // console.log('deleted', res)
      // setSessions(prev => {
      //   const newData = [...prev]
      //   const index = prev.findIndex(session => session.id === id)
      //   delete newData[index]
      //   // console.log('new sessions', newData)
      //   return newData
      // })
      setGuestUpdate({})
      // setGuestLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Deleting Guest')
        enqueueSnackbar('Error Deleting Guest', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
        setGuestLoading(false)
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const banGuest = useCallback((event, { id, sessionId, tableData }, { banReason }) => {
    setGuestLoading(true)
    const token = oktaAuth.getAccessToken()
    banGuestPromise(id, sessionId, banReason, token).then((res) => {
      setGuestUpdate({})
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Banning Guest')
        enqueueSnackbar('Error Banning Guest', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
        setGuestLoading(false)
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const submitGuest = useCallback(async (event, submitData) => {
    setGuestLoading(true)
    setGuestModalOpen(false);
    const data = guestSelected ? difference(guestSelected, submitData) : cloneDeep(submitData)
    const guestRole = data.role || guestSelected.role
    console.log('SUBMIT CHANGES:', data)
    // if (!isEqual(guestSelected, submitData)) {
    if (Object.keys(data).length) {
      // const data = cloneDeep(submitData)
      if (guestSelected) data.id = guestSelected.id
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && data[i] == null) delete data[i]
      }
      if (data.tableData) delete data.tableData
      if (data.segments) {
        data.segments = JSON.stringify(data.segments)
      }
      if (data.notes) {
        data.notes = data.notes.trim();
      }
      // for (let i in data.segments) {
      //   if (typeof data.segments[i] !== 'boolean' && !data.segments[i]) delete data.segments[i]
      // }
      // if (data.techCheckTime) data.techCheckTime = moment(data.techCheckTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      delete data.techCheckTime
      delete data.checkedInTime
      if (guestRole !== 'Viewer') {
        delete data.industryCheck
        delete data.recruiterCode
        delete data.focusGroup
        delete data.segments
      }
      delete data.photo
      // if (!data.sessionId) data.sessionId = short.generate()
      let submitPromise;
      if (session.bypassTechCheck && data.state === 'Registered') data.state = 'Approved'
      if (!data.id) {
        data.sessionId = session.id
        // data.state = data.role === 'Viewer' ? 'Registered' : 'Approved'
        data.createdBy = user.email
        data.lastUpdatedBy = user.email
        submitPromise = insertGuestPromise
      } else {
        data.sessionId = session.id
        data.lastUpdatedBy = user.email
        submitPromise = updateGuestPromise
      }
      const token = oktaAuth.getAccessToken()
      try {
        const res = await submitPromise(data, token)
        console.log('updated', res)
        if (submitData.photo && submitData.photo instanceof File) {
          await uploadGuestPhotoPromise(submitData.photo, session.id, data.id || res.insertId, token)
        }
        // setSessions(prev => {
        //   const newData = [...prev]
        //   const index = prev.findIndex(session => session.sessionId === data.sessionId)
        //   newData[index] = data
        //   // console.log('new sessions', newData)
        //   return newData
        // })
        setGuestUpdate({})
        if (!res.numberOfRecordsUpdated) {
          // setError('Guest Email is Banned')
          enqueueSnackbar('Guest Email is Banned', { preventDuplicate: true, action: DismissBtn });
          // setHideErrorManually(true)
          setGuestLoading(false)
        }
        // setGuestLoading(false)
      } catch (error) {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Guest')
          enqueueSnackbar('Error Saving Guest', { preventDuplicate: true, action: DismissBtn });
          // setHideErrorManually(true)
          setGuestUpdate({})
          setGuestLoading(false)
        }
      }
    } else {
      setGuestUpdate({})
      setGuestLoading(false)
    }
    // setGuestModalOpen(false);
    // clearLocalStorage();
    setGuestSelected(null)
  }, [session, guestSelected, oktaAuth, setGuestModalOpen, setGuestSelected, enqueueSnackbar, user.email]);

  const toggleGuestFocusGroup = useCallback((event, row) => {
    setGuestCheckIn(prev => new Set([...prev, row.id]))
    const isAdding = !row.selectedFocusGroup
    const data = {
      id: row.id,
      sessionId: row.sessionId,
      selectedFocusGroup: isAdding
    }
    const token = oktaAuth.getAccessToken()
    updateGuestPromise(data, token).then((res) => {
      setGuestUpdate({})
      setGuestCheckIn(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError(`Guest Sucessfully ${isAdding ? 'Added to' : 'Removed from'} Focus Group`)
      enqueueSnackbar(`Guest Sucessfully ${isAdding ? 'Added to' : 'Removed from'} Focus Group`, { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestCheckIn(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError(`Error ${isAdding ? 'Adding Guest to' : 'Removing Guest from'} Focus Group`)
        enqueueSnackbar(`Error ${isAdding ? 'Adding Guest to' : 'Removing Guest from'} Focus Group`, { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const turnAwayGuest = useCallback((event, row, isNonInteractiveFG) => {
    setGuestTurnAway(prev => new Set([...prev, row.id]))
    const newState = row.state === 'Approved' || isNonInteractiveFG ? 'Turn Away' : 'Approved'
    const data = {
      id: row.id,
      sessionId: row.sessionId,
      state: newState
    }
    const token = oktaAuth.getAccessToken()
    updateGuestPromise(data, token).then((res) => {
      setGuestUpdate({})
      setGuestTurnAway(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError(`Guest Successfully Set To ${newState}`)
      enqueueSnackbar(`Guest Successfully Set To ${newState}`, { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestTurnAway(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError(`Error Setting Guest To ${newState}`)
        enqueueSnackbar(`Error Setting Guest To ${newState}`, { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const batchUpdate = useCallback((event, ids, updateData) => {
    setBatchLoading(true)
    const token = oktaAuth.getAccessToken()
    batchUpdateGuestPromise(session.id, ids, updateData, token).then((res) => {
      setGuestUpdate({})
      setBatchLoading(false)
      // setError(`Guests Successfully Updated`)
      enqueueSnackbar('Guests Successfully Updated', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError(`Error Updating Guests`)
        enqueueSnackbar('Error Updating Guests', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
        setBatchLoading(false)
      }
    })
  }, [session, oktaAuth, enqueueSnackbar])

  const moveGuests = useCallback((guests, newSessionId, oldSessionId) => {
    const token = oktaAuth.getAccessToken()

    moveGuestToNewSessionPromise(guests, newSessionId, oldSessionId, token)
      .then((res) => {
        setGuestUpdate({})
        enqueueSnackbar('Guests Successfully Moved', { preventDuplicate: true, action: DismissBtn });
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          enqueueSnackbar('Error Moving Guests', { preventDuplicate: true, action: DismissBtn });
          setGuestUpdate({})
        }
      })
  }, [session, oktaAuth])

  const noShowGuest = useCallback((event, row) => {
    setGuestTurnAway(prev => new Set([...prev, row.id]))
    const token = oktaAuth.getAccessToken()
    cancelGuestPromise(row.sessionId, [row.id], { noShow: true }, token).then((res) => {
      setGuestUpdate({})
      setGuestTurnAway(prev => {
        const data = new Set([...prev])
        data.delete(row.id)
        return data
      })
      // setError(`Guest Successfully Set To No Show`)
      enqueueSnackbar('Guest Successfully Set to No Show', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestTurnAway(prev => {
          const data = new Set([...prev])
          data.delete(row.id)
          return data
        })
        // setError(`Error Setting Guest To No Show`)
        enqueueSnackbar('Error Setting Guest to No Show', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [oktaAuth, enqueueSnackbar]);

  const noShowBatch = useCallback((event, rows) => {
    setBatchLoading(true)
    const ids = rows.map(x => x.id)
    setGuestTurnAway(prev => new Set([...prev, ...ids]))
    const token = oktaAuth.getAccessToken()
    cancelGuestPromise(session.id, ids, { noShow: true }, token).then((res) => {
      setGuestUpdate({})
      setGuestTurnAway(prev => {
        const data = new Set([...prev])
        ids.forEach(gid => data.delete(gid))
        return data
      })
      setBatchLoading(false)
      // setError(`Guests Successfully Set To No Show`)
      enqueueSnackbar('Guests Successfully Set to No Show', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestTurnAway(prev => {
          const data = new Set([...prev])
          ids.forEach(gid => data.delete(gid))
          return data
        })
        // setError(`Error Setting Guests To No Show`)
        enqueueSnackbar('Error Setting Guests To No Show', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
        setBatchLoading(false)
      }
    })
  }, [session, oktaAuth, enqueueSnackbar]);

  const canceledGuest = useCallback((event, guestIds = [], fields) => {
    setGuestCanceled(prev => new Set([...prev, ...guestIds]))
    const label = guestIds.length > 1 ? 'Guests' : 'Guest'
    const cancelNote = !!fields?.message ? fields.message : undefined
    const token = oktaAuth.getAccessToken()
    cancelGuestPromise(session.id, guestIds, { cancelNote }, token).then((res) => {
      setGuestUpdate({})
      setGuestCanceled(prev => {
        const data = new Set([...prev])
        guestIds.forEach(gid => data.delete(gid))
        return data
      })
      // setError(`${label} Successfully Set To Canceled`)
      enqueueSnackbar(`${label} Successfully Set To Canceled`, { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        setGuestCanceled(prev => {
          const data = new Set([...prev])
          guestIds.forEach(gid => data.delete(gid))
          return data
        })
        // setError(`Error Setting ${label} To Canceled`)
        enqueueSnackbar(`Error Setting ${label} To Canceled`, { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
      }
    })
  }, [session, oktaAuth, enqueueSnackbar]);

  const openChat = useCallback(async (guest, techCheck) => {
    console.log('OPEN CHAT FOR', guest)
    if (messageUserRef.current) {
      setShowChat(prev => {
        return { open: true }
      })
      const chimeName = `${guest.firstName} ${guest.lastName.charAt(0).toUpperCase()}`
      await messageUserRef.current(guest.id, chimeName, techCheck)
      const message = {
        action: 'guestChat',
        guestId: guest.id
      }
      sendMessageRef.current(JSON.stringify(message));
    }
  }, [setShowChat]);

  const sendGuestMessage = useCallback((guest, messageText) => {
    if (!!messageText) {
      // console.log(data)
      const message = {
        action: 'guestMessage',
        connectionId: guest.connectionId,
        messageText: messageText,
        audience: guest.audience,
        status: guest.status
        // adminTime: Date.now()
      }
      sendMessageRef.current(JSON.stringify(message));
    }
  }, []);

  const onChatStarted = useCallback(() => {
    // setChatStarted(true)
    // setChatDisabled()
  }, []);

  const chatConnectionChange = useCallback((connected) => {
    // setChatConnected(connected)
    setChatDisabled(!connected)
  }, []);

  const handleGuestEject = useCallback((event, guests, { ejectReason, doNotPay, banned, banReason }) => {
    const guestData = guests.map((guest) => {
      console.log(guest, guest.id, guest.connectionId)
      return {
        id: guest.id,
        connectionId: guest.connectionId
      }
    })
    // const message = {
    //   action: 'ejectGuest',
    //   guestId: guest.id,
    //   connectionId: guest.connectionId || 'ALL',
    //   status: guest.status || guest.userStatus
    //   // adminTime: Date.now()
    // }
    const message = {
      action: 'ejectGuest',
      guestData,
      ejectReason,
      doNotPay,
      banned,
      banReason
    }
    // console.log(message)
    sendMessageRef.current(JSON.stringify(message));
  }, []);

  const handleForceRefresh = useCallback((event, { id }) => {
    const message = {
      action: 'forceRefresh',
      guestId: id
    }
    sendMessageRef.current(JSON.stringify(message));
  }, []);

  const handleMoveForward = useCallback((event, { id }) => {
    const playlistLength = session.contentLength?.length || 0
    const message = {
      action: 'moveForward',
      guestId: id,
      complete: !!playlistLength ? videoIndex >= playlistLength - 1 : true,
      postSurvey: !!(session.surveyPlacement && session.surveyPlacement !== 'pre')
    }
    sendMessageRef.current(JSON.stringify(message));
  }, [session, videoIndex]);

  const handleMoveComplete = useCallback((event, { id }) => {
    const message = {
      action: 'moveForward',
      guestId: id,
      complete: true,
      postSurvey: false
    }
    sendMessageRef.current(JSON.stringify(message));
  }, []);

  const handleResetGuestConnection = useCallback((event, { id }) => {
    const message = {
      action: 'resetConnection',
      guestId: id
    }
    sendMessageRef.current(JSON.stringify(message));
  }, []);

  const getGuestPhoto = useCallback((guestId, filename) => {
    const token = oktaAuth.getAccessToken()
    return getGuestPhotoPromise(sessionId, guestId, filename, token)
  }, [sessionId, oktaAuth])

  const handleFileError = useCallback((err) => {
    console.log(err)
    const errMessage = (err[0] && err[0].type) && err[0].type.replace(/([A-Z]+)/g, ' $1')
    // setError(`Error Uploading File${errMessage ? ` - ${errMessage.charAt(0).toUpperCase() + errMessage.slice(1)}` : ''}`)
    enqueueSnackbar(`Error Uploading File${errMessage ? ` - ${errMessage.charAt(0).toUpperCase() + errMessage.slice(1)}` : ''}`, { preventDuplicate: true, action: DismissBtn });
    // setHideErrorManually(true)
  }, [enqueueSnackbar])

  const handleCSVUpload = useCallback(([file], mockUpload) => {
    setGuestLoading(true)
    const token = oktaAuth.getAccessToken()
    uploadGuestCSVPromse(file, sessionId, token, mockUpload).then((res) => {
      // console.log('updated', res)
      // setSessions(prev => {
      //   const newData = [...prev]
      //   const index = prev.findIndex(session => session.sessionId === data.sessionId)
      //   newData[index] = data
      //   // console.log('new sessions', newData)
      //   return newData
      // })
      // setError('Guest CSV Uploaded')
      enqueueSnackbar('Guest CSV Uploaded', { preventDuplicate: true, action: DismissBtn });
      setGuestUpdate({})
      // setGuestLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log(error)
        // setError('Error Uploading Guest CSV')
        enqueueSnackbar('Error Uploading Guest CSV', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setGuestUpdate({})
        setGuestLoading(false)
      }
    })
  }, [sessionId, oktaAuth, enqueueSnackbar]);

  const getProjects = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getProjectsPromise(token).then((res) => {
      res.push({
        projectName: 'New Project',
        id: 'NEW'
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('videos error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getProjectSessions = useCallback(async () => {
    if (session?.projectId) {
      const token = oktaAuth.getAccessToken()
      const res = await getProjectSessionsPromise(session.projectId, token);
      return res;
    } else return []
  }, [oktaAuth, session])

  const getChatCredentials = useCallback(async (chatInstance) => {
    const token = oktaAuth.getAccessToken()
    return getChatCredentialPromise(user.chimeUsername, chatInstance, token)
  }, [oktaAuth, user.chimeUsername])

  const getPFMScreenings = useCallback(async (linked) => {
    const token = oktaAuth.getAccessToken()
    return getPFMScreeningsPromise(linked, token).then((res) => {
      // res.unshift({
      //   title: 'New PFM Online Screening',
      //   id: 'NEW',
      // })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('screenings error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getSurveys = useCallback(async (surveyType, forRegistration) => {
    const token = oktaAuth.getAccessToken()
    return getSurveysPromise(surveyType.toLowerCase(), { forRegistration }, token).then((res) => {
      if (forRegistration) {
        res.unshift({
          name: 'New Registration Survey',
          id: 'NEW',
        })
      }
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('surveys error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getAppointmentTypes = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getAcuityAppointmentTypes(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('appointments error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getCalendars = useCallback(async (type) => {
    const token = oktaAuth.getAccessToken()
    return getAcuityCalendars(type, token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('appointments error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getYoutubeVideo = useCallback(async (vid) => {
    console.log('get youtube vid', vid)
    const token = oktaAuth.getAccessToken()
    return getYoutubeVideoPromise(vid, token)
  }, [oktaAuth])

  useEffect(() => {
    if(sessionInfo && videoPlatformRef.current === 'IVS'){
      function getIVSVideo(vid) {
        const token = oktaAuth.getAccessToken()
        return getIVSVideoPromise(vid, sessionInfo.id, token)
      };
      let channelId = sessionInfo.ivsChannelArn.split('channel/').pop();
      const res = getIVSVideo(channelId).then(res => {
        ivsResponseRef.current = res.streamSession
      })
    }
  }, [sessionInfo])

  const getVideos = useCallback(async (platform, teamFilter, playlists = false) => {
    const token = oktaAuth.getAccessToken()
    return getVideosPromise(platform, teamFilter, playlists, token).then((res) => {
      if (teamFilter !== 'TC') res.push({
        [platform.toLowerCase() === 'brightcove' ? 'name' : 'videoTitle']: `Add New ${playlists ? 'Playlist' : 'Video'}`,
        [platform.toLowerCase() === 'brightcove' ? 'id' : 'videoId']: 'NEW',
        [platform.toLowerCase() === 'brightcove' ? 'duration' : 'videoLength']: null,
        techCheck: false
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('videos error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getIVSChannels = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getIVSChannelsPromise(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('ivs channels error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getZoomUsers = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getUsersPromise(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('zoom users error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getZoomUserMeetings = useCallback(async (userId) => {
    const token = oktaAuth.getAccessToken()
    return getUserMeetingsPromise(userId, token).then((res) => {
      res.unshift({
        topic: 'New Meeting',
        id: 'NEW',
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('zoom user meetings error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getTangoCustomers = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getCustomersPromise(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('tango customers error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getTangoCatalogs = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getCatalogsPromise(token).then(catalogs => (
      catalogs.brands.map(brand => brand.items).flat()
    )).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('tango catalogs error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getTangoEmailTemplates = useCallback(async () => {
    const token = oktaAuth.getAccessToken()
    return getEmailTemplatesPromise(token).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('tango email templates error', error)
        return []
      }
    })
  }, [oktaAuth])

  const editSession = useCallback(() => {
    setSessionModalOpen(true);
  }, [setSessionModalOpen])

  const handleCloseSessionDialog = useCallback(() => {
    // clearLocalStorage();
    setSessionModalOpen(false);
  }, [setSessionModalOpen]);

  const submitSession = useCallback((event, submitData) => {
    setLoading(true)
    if (submitData.audienceSegments) submitData.audienceSegments = submitData.audienceSegments.map(x => {
      delete x.collapsed
      x.questionKey = x.questionKey.replace(/\s*$/, '')
      x.answers.forEach(y => {
        if (y.display) y.display = y.display.replace(/\s*$/, '')
        y.answer = y.answer.replace(/\s*$/, '')
      })
      if (x.matrix && x.matrixKeys) {
        x.matrixKeys.forEach(y => {
          if (y.display) y.display = y.display.replace(/\s*$/, '')
          y.option = y.option.replace(/\s*$/, '')
        })
      } else {
        x.matrixKeys = undefined
      }
      return x
    })
    if (submitData.virtualSegments) submitData.virtualSegments = submitData.virtualSegments.map(x => {
      delete x.collapsed
      return x
    })
    const linkedScreenings = submitData.externalRecruitId && submitData.linkedScreenings ? [...submitData.linkedScreenings, submitData.externalRecruitId].sort() : null
    delete submitData.linkedScreenings
    const data = difference(session, submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {
      const vidId = data.hasOwnProperty('videoId') ? data.videoId : session.videoId
      const multiSection = data.hasOwnProperty('multiSection') ? data.multiSection : session.multiSection
      const vidLength = data.hasOwnProperty('videoLength') ? data.videoLength : session.videoLength
      const contentLength = data.hasOwnProperty('contentLength') ? data.contentLength : session.contentLength
      const autStop = data.hasOwnProperty('autoStop') ? data.autoStop : session.autoStop
      const geoFilter = data.hasOwnProperty('enableGeoRestriction') ? data.enableGeoRestriction : session.enableGeoRestriction
      const geoFilterX = data.hasOwnProperty('enhancedGeoRestriction') ? data.enhancedGeoRestriction : session.enhancedGeoRestriction
      const subGeoRestriction = data.hasOwnProperty('subGeoRestrictionType') ? data.subGeoRestrictionType : session.subGeoRestrictionType
      const preventLate = data.hasOwnProperty('preventLateEntry') ? data.preventLateEntry : session.preventLateEntry
      const limitDisconnect = data.hasOwnProperty('limitDisconnect') ? data.limitDisconnect : session.limitDisconnect
      const isDialSession = data.hasOwnProperty('isDialSession') ? data.isDialSession : session.isDialSession
      const dialMode = data.hasOwnProperty('dialMode') ? data.dialMode : session.dialMode
      const externalRecruit = data.hasOwnProperty('externalRecruit') ? data.externalRecruit : session.externalRecruit
      const recruitPlatform = data.hasOwnProperty('recruitPlatform') ? data.recruitPlatform : session.recruitPlatform
      const externalRecruitId = data.hasOwnProperty('externalRecruitId') ? data.externalRecruitId : session.externalRecruitId
      const hasQualifyingSegment = data.hasOwnProperty('hasQualifyingSegment') ? data.hasQualifyingSegment : session.hasQualifyingSegment
      const hasAcuity = data.hasOwnProperty('acuityTechCheck') ? data.acuityTechCheck : session.acuityTechCheck
      const hasSurvey = data.hasOwnProperty('hasSurvey') ? data.hasSurvey : session.hasSurvey
      const hasZoom = data.hasOwnProperty('hasZoomMeeting') ? data.hasZoomMeeting : session.hasZoomMeeting
      const hasMagicLink = data.hasOwnProperty('clientMagicLink') ? data.clientMagicLink : session.clientMagicLink
      const hasPasscode = data.hasOwnProperty('requirePasscode') ? data.requirePasscode : session.requirePasscode
      const vidPlatform = data.hasOwnProperty('videoPlatform') ? data.videoPlatform : session.videoPlatform
      const segments = data.hasOwnProperty('audienceSegments') ? data.audienceSegments : session.audienceSegments
      const vSegments = data.hasOwnProperty('virtualSegments') ? data.virtualSegments : session.virtualSegments
      const hasProject = data.hasOwnProperty('hasProject') ? data.hasProject : session.hasProject
      const chartConfig = data.hasOwnProperty('chartConfig') ? data.chartConfig : cloneDeep(session.chartConfig)
      const type = data.hasOwnProperty('type') ? data.type : session.type
      // if (!isEqual(session, submitData)) {
      // const data = cloneDeep(submitData)
      data.type = type;
      if (session) {
        data.id = session.id
        data.chimeChatInstance = session.chimeChatInstance
      }
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
        // if ((Array.isArray(data[i]) && !data[i].length) || (typeof data[i] !== 'boolean' && !data[i])) delete data[i]
      }
      if (data.tableData) delete data.tableData
      if (!hasProject) {
        data.projectId = null
      }
      // const genderSegment = data.audienceSegments.find(x => x.answers.find(ans => ans.questionKey === 'Gender' || !!['Male', 'Female'].includes(ans.answer)))
      // if (!!genderSegment) {
      if (data.audienceSegments || data.virtualSegments) {
        const segmentGoals = [...(segments || []), ...(vSegments || [])].reduce((p, c) => {
          p[c.questionKey] = c.answers.reduce((pa, pc) => (pa[pc.answer] = typeof pc.goal === 'object' ? !pc.goal ? null : { ...pc.goal } : parseFloat(pc.goal), pa), {})
          return p
        }, {})
        data.segmentGoals = JSON.stringify(segmentGoals)
      }
      if (data.audienceSegments) {
        const dialChartSegments = session && cloneDeep(session.dialChartSegments) || []
        // if (dialChartSegments && dialChartSegments.length) {
        data.audienceSegments.forEach((segment) => {
          segment.answers.forEach(answer => delete answer.goal)
          if (!segment.freeNumeric && !isEqual(segment.answers.map(x => x.answer), dialChartSegments.find(x => x.questionKey === segment.questionKey)?.answers.map(x => x.answer))) {
            const index = dialChartSegments.findIndex(x => x.questionKey === segment.questionKey)
            if (index !== -1 || (segment.questionKey === 'Gender' || segment.answers.reduce((p, c) => p || ['Male', 'Female'].includes(c.answer), false))) {
              const newChartAns = segment.answers.map(ans => {
                const newAns = cloneDeep(ans)
                delete newAns.goal
                const shouldShow = dialChartSegments[index]?.answers.find(x => x.answer === newAns.answer)?.showOnChart
                newAns.showOnChart = typeof shouldShow === 'boolean' ? shouldShow : (['Male', 'Female'].includes(newAns.answer) || false)
                return newAns
              })
              if (index === -1) {
                const newSeg = cloneDeep(segment)
                delete newSeg.multiple
                delete newSeg.question
                delete newSeg.collapsed
                newSeg.hasCrosstab = false
                newSeg.crosstabKeys = []
                newSeg.answers = newChartAns
                dialChartSegments.unshift(newSeg)
              } else {
                dialChartSegments[index].answers = newChartAns
              }
            }
          }
        })
        if (!hasProject || !!data.projectId) {
          data.dialChartSegments = JSON.stringify(dialChartSegments)
          data.audienceSegments = JSON.stringify(data.audienceSegments)
          if (externalRecruit && externalRecruitId) {
            data.externalRecruitId = externalRecruitId
          }
        } else {
          delete data.dialChartSegments
          delete data.audienceSegments
        }
      }
      if (data.virtualSegments) {
        data.virtualSegments.forEach((segment) => {
          segment.answers.forEach(answer => delete answer.goal)
        })
        data.virtualSegments = JSON.stringify(data.virtualSegments)
      }
      if (data.chimeChatChannels) {
        const oldChannels = session.chimeChatChannels || []
        const deletes = oldChannels.filter(x => !data.chimeChatChannels.find(y => x.channelId === y.channelId)).map(x => ({ name: x.name, channelId: x.channelId }))
        const updates = data.chimeChatChannels.filter(x => oldChannels.find(y => x.channelId === y.channelId && (x.name !== y.name || x.type !== y.type || x.restricted !== y.restricted))).map(x => ({ name: x.name, channelId: x.channelId, type: x.type, restricted: x.restricted }))
        data.chimeChatChanges = { deletes, updates }
        data.chimeChatChannels = JSON.stringify(data.chimeChatChannels)
      }
      if (!hasAcuity) {
        data.acuityAppointmentTypeId = null
        data.acuityCalendarId = null
      }
      if (!externalRecruit) {
        data.recruitPlatform = null
        data.externalRecruitId = null
        data.pauseExternalRecruit = false
      } else if (data.externalRecruitId) {
        data.externalRecruit = true
        // data.audienceSegments = data.audienceSegments || segments
        data.recruitPlatform = data.recruitPlatform || recruitPlatform
        if (hasProject && recruitPlatform === 'PFM' && linkedScreenings?.length) {
          data.linkedScreenings = linkedScreenings
        } else if (recruitPlatform === 'QTC') {
          data.audienceSegments = data.audienceSegments || (segments && JSON.stringify(segments))
        }
      }
      if (!hasQualifyingSegment) { //check for project
        data.hasQualifyingSegment = false
      }
      if (data.qualifyingSegment) {
        data.qualifyingSegment = JSON.stringify(data.qualifyingSegment)
      }
      if (type === 'Focus Group') {
        data.videoId = null
        data.videoPlatform = null
        data.videoLength = null
        data.contentLength = null
        data.autoStopTime = null
        data.isLiveSession = false
        data.multiSection = false
        data.internalVideoName = null
        data.videoMarkers = null
      } else {
        if (!vidLength || !contentLength?.length) {
          data.videoLength = null
          data.contentLength = null
        }
        data.videoId = vidId
        data.videoPlatform = vidPlatform
        if (vidPlatform === 'Brightcove') {
          data.isLiveSession = false
          if (vidId === 'NEW') data.multiSection = multiSection
        }
        if (data.contentLength) data.contentLength = JSON.stringify(data.contentLength)
        if (!autStop) data.autoStopTime = null
        if (data.internalVideoName) data.internalVideoName = JSON.stringify(data.internalVideoName)
        if (data.videoMarkers) data.videoMarkers = JSON.stringify(data.videoMarkers)
      }

      if (data.allowedBrowsers) data.allowedBrowsers = JSON.stringify(data.allowedBrowsers)
      if (!geoFilter) {
        data.allowedCountries = []
      }
      if (!geoFilter || (!geoFilterX || !subGeoRestriction)) {
        data.subGeoRestriction = null
      }
      if (data.allowedCountries) data.allowedCountries = JSON.stringify(data.allowedCountries)
      if (data.subGeoRestriction) data.subGeoRestriction = JSON.stringify(data.subGeoRestriction)
      if (!preventLate) {
        data.lateEntryTimeLimit = null
      }
      if (!limitDisconnect) {
        data.disconnectTimeLimit = null
        data.disconnectCountLimit = null
      }
      if (!isDialSession || type === 'Focus Group') {
        // data.dialBranding = null
        data.dialStyle = null
        data.dialMotion = null
        data.dialSnap = null
        data.gaugeStyle = null
        data.numberOfMeters = null
        data.scoreBase = null
        data.sliderKnob = null
        data.sliderKnobColor = null
        data.includeTuneOut = false
        data.inactiveReminder = false
        data.inactiveInterval = null
        data.inactiveMessage = null
        data.clientDialViewer = false
        data.symbolOptions = null
        data.inputTime = null
        data.chartConfig = null;
      } else {
        if (dialMode === 'symbol') {
          if (data.symbolOptions) {
            chartConfig.defaultEmoji = data.symbolOptions[0].emoji;
            data.chartConfig = chartConfig;
            data.symbolOptions = JSON.stringify(data.symbolOptions)
          }
          if (data.chartConfig) data.chartConfig = JSON.stringify(data.chartConfig);
          data.dialMotion = null
          data.dialSnap = null
          data.gaugeStyle = null
          data.numberOfMeters = null
          data.scoreBase = null
          data.sliderKnob = null
          data.sliderKnobColor = null
        } else if (dialMode === 'score') {
          data.symbolOptions = null
          data.chartConfig = null
          data.inputTime = null
        } else {
          data.dialMotion = null
          data.dialSnap = null
          data.gaugeStyle = null
          data.numberOfMeters = null
          data.scoreBase = null
          data.sliderKnob = null
          data.sliderKnobColor = null
          data.symbolOptions = null
          data.chartConfig = null
          data.inputTime = null
          data.clientDialViewer = false;
        }
      }
      if (!hasSurvey) {
        data.surveyType = null
        data.surveyId = null
        data.surveyPlacement = null
      }
      if (!hasZoom) {
        data.zoomHostId = null
        data.zoomMeetingId = null
      } else if (data.zoomMeetingId === 'NEW') {
        if (!data.zoomHostId) data.zoomHostId = session.zoomHostId
        data.zoomMeetingName = data.sessionName || session.sessionName
        data.zoomMeetingTime = moment.tz(data.guestCheckInTime || session.guestCheckInTime, "America/Los_Angeles").seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')
      }
      if (!hasMagicLink) {
        data.magicLinkUrl = null
        data.requirePasscode = false
        data.magicLinkPasscode = null
        data.requireEmailValidation = false
        data.requireApproval = false
      } else if (!hasPasscode) {
        data.magicLinkPasscode = null
      }
      delete data.sessionStartTime
      delete data.contentStartTime
      delete data.sessionEndTime
      delete data.liveStartTime
      delete data.videoIndex
      delete data.videoStartTime
      delete data.videoEndTime
      delete data.chartVideoStart
      delete data.chartVideoEnd
      data.lastUpdatedBy = user.email
      // FOR MYSQL DATA API
      if (data.scheduledStartTime) data.scheduledStartTime = moment(data.scheduledStartTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      if (data.staffArrivalTime) data.staffArrivalTime = moment(data.staffArrivalTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      if (data.guestCheckInTime) data.guestCheckInTime = moment(data.guestCheckInTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss")
      if (data.clientArrivalTime) data.clientArrivalTime = moment(data.clientArrivalTime).seconds(0).utc().format("YYYY-MM-DD H:mm:ss");
      const token = oktaAuth.getAccessToken()

      updateSessionPromise(data, token).then((res) => {
        setUpdate({})
        // setLoading(false)
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Session')
          enqueueSnackbar('Error Saving Session', { preventDuplicate: true, action: DismissBtn });
          // setHideErrorManually(true)
          setUpdate({})
          setLoading(false)
        }
      })

    } else {
      // console.log('NO CHANGES MADE')
      setUpdate({})
      setLoading(false)
    }
    setSessionModalOpen(false);
    // clearLocalStorage();
  }, [session, oktaAuth, setSessionModalOpen, enqueueSnackbar, user.email]);

  const submitSessionRewards = useCallback(async (data) => {
    if (data) {
      const submitPromise = !data.id ? insertRewardsPromise : updateRewardsPromise
      const token = oktaAuth.getAccessToken()
      submitPromise(data, token).then((res) => {
        console.log('updated rewards', res)
        setRewardsUpdate({})
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Session Rewards')
          enqueueSnackbar('Error Saving Session Rewards', { preventDuplicate: true, action: DismissBtn });
          // setHideErrorManually(true)
          setRewardsUpdate({})
        }
      })
    } else {
      setRewardsUpdate({})
    }
  }, [oktaAuth, enqueueSnackbar]);

  const submitGuestReward = useCallback(async (data) => {
    if (data) {
      const token = oktaAuth.getAccessToken()
      await updateGuestRewardsPromise(data, token).then((res) => {
        console.log('updated reward', res)
        setRewardsUpdate({ guestsOnly: true })
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Guest Reward')
          enqueueSnackbar('Error Saving Guest Reward', { preventDuplicate: true, action: DismissBtn });
          // setHideErrorManually(true)
          setRewardsUpdate({ guestsOnly: true })
        }
      })
    } else {
      setRewardsUpdate({ guestsOnly: true })
    }
  }, [oktaAuth, enqueueSnackbar]);

  const submitRewardsOrder = useCallback(async (id) => {
    const token = oktaAuth.getAccessToken()
    await submitRewardOrderPromise(id, token).then((res) => {
      console.log('submitted rewards order', res)
      // setError('Rewards Order Sucessfully Submitted')
      enqueueSnackbar('Rewards Order Sucessfully Submitted', { preventDuplicate: true, action: DismissBtn });
      setRewardsUpdate({})
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Submitting Rewards Order')
        enqueueSnackbar('Error Submitting Rewards Order', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
        setRewardsUpdate({})
      }
    })
  }, [oktaAuth, enqueueSnackbar]);

  const handleBulkZoomRegister = useCallback(async (event, rows) => {
    const token = oktaAuth.getAccessToken()
    let guests = rows.filter(i => !i.zoomRegistrantId && i.state === 'Approved')
    if (guests.length > 0) {
      setZoomLoading(true);
      await zoomRegisterPromise(session.zoomMeetingId, guests, true, session.overrideZoomName, token).then((res) => {
        // res will be given here
        enqueueSnackbar('Guest Successfully Registered to Zoom', { preventDuplicate: true, action: DismissBtn });
        setZoomLoading(false);
        setGuestUpdate({});
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          enqueueSnackbar(error.message ? `Zoom Register Error - ${error.message}` : 'Error Registering to Zoom', { preventDuplicate: true, action: DismissBtn });
          // setHideErrorManually(true)
        }
        setZoomLoading(false);
        setGuestUpdate({});
      })
    } else {
      enqueueSnackbar(`Selected Guests are already registered to Zoom`, { preventDuplicate: true, action: DismissBtn });
    }
  }, [session, oktaAuth])

  const resendGuestReward = useCallback(async (id) => {
    setGuestRewardResend(prev => new Set([...prev, id]))
    const token = oktaAuth.getAccessToken()
    await resendGuestRewardPromise(id, token).then((res) => {
      console.log('resent guest reward', res)
      // setError('Guest Reward Sucessfully Resent')
      enqueueSnackbar('Guest Reward Sucessfully Resent', { preventDuplicate: true, action: DismissBtn });
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError(error.message ? `Tango Error - ${error.message}` : 'Error Resending Guest Reward')
        enqueueSnackbar(error.message ? `Tango Error - ${error.message}` : 'Error Resending Guest Reward', { preventDuplicate: true, action: DismissBtn });
        // setHideErrorManually(true)
      }
    }).finally(() => {
      setGuestRewardResend(prev => {
        const data = new Set([...prev])
        data.delete(id)
        return data
      })
      setRewardsUpdate({})
    })
  }, [oktaAuth, enqueueSnackbar]);

  const exportSessionSegments = useCallback(async () => {
    const segs = session.audienceSegments || []
    // const builder = new CsvBuilder(`Session ${session.id} Segments.csv`);
    const columns = ['Segment Key', 'Type', 'Matrix Option', 'Segment Value', 'Decipher Question ID', 'Exclude from Recruit']
    const rows = []
    segs.forEach((s, i) => {
      if (s.freeNumeric) {
        rows.push([s.questionKey, 'Numeric', '', '', `VSR${i}`, s.excluded ? "YES" : "NO"])
      } else if (s.matrix) {
        rows.push(...s.matrixKeys.map(k => s.answers.map(x => [s.questionKey, 'Matrix', k.option, `"${x.answer}"`, `VSR${i}`, s.excluded ? "YES" : "NO"])).flat())
      } else {
        rows.push(...s.answers.map(x => [s.questionKey, s.multiple ? 'Multi' : 'Single', '', `"${x.answer}"`, `VSR${i}`, s.excluded ? "YES" : "NO"]))
      }
    })
    // builder
    //   .setColumns(['Segment Key', 'Type', 'Matrix Option', 'Segment Value'])
    //   .addRows(rows)
    //   // .addRows(segs.map((s) => [s.questionKey, s.freeNumeric ? 'Numeric Input' : s.answers.map(x => x.answer).join('\n')]))
    //   .exportFile();
    const csvData = [columns, ...rows].join('\r\n')
    const zipFile = new JSZip()
    zipFile.file(`Session ${session.id} Segments.csv`, csvData)
    if (session.hasSurvey && session.surveyType === 'Decipher') {
      const { part, full } = exportDecipherScript([...(session.audienceSegments || []), ...(session.virtualSegments || [])], session.surveyPlacement, (session.contentLength || []).length || 1)
      zipFile.file(`Session ${session.id} Decipher.txt`, part)
      zipFile.file(`Session ${session.id} Decipher.xml`, full)
    }
    const zipData = await zipFile.generateAsync({ type: 'blob' })
    const href = URL.createObjectURL(zipData)
    Object.assign(document.createElement('a'), {
      href,
      download: `Session ${session.id} Segment Data.zip`,
    }).click()
  }, [session])

  const sessionLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const guestsLoaded = useCallback(() => {
    setGuestLoading(false)
  }, [])

  const allowedChatChannels = useMemo(() => {
    const channels = cloneDeep((session && session.chimeChatChannels) || [])
    return channels.filter(x => x.staffAccess.filter(y => user.accessLevels.includes(y))).map(x => (x.userList = !x.userAccess.includes('Viewer'), x))
  }, [session, user])

  useEffect(() => {
    if (session && session.id) {
      setVideoIndex(session.videoIndex || 0)
      setShowChat(prev => {
        if (!(session.chimeChatInstance && allowedChatChannels.length)) {
          return false
        } else if (!prev) {
          return { open: false }
        }
        return prev
      })
    }
  }, [session, allowedChatChannels, setShowChat])

  const manageChannelGuests = useCallback((channelData, channelGuests) => {
    const channel = allowedChatChannels.find(x => x.channelId === channelData.id)
    if (channel) {
      const channelUserData = {
        channelGuests: channelGuests.map(x => x.userId),
        channelData,
        allowedRoles: channel.userAccess
      }
      console.log('OPEN GUEST MANAGEMENT FOR CHAT CHANNEL', channelUserData)
      setChimeChannelUserData(channelUserData)
    }
  }, [allowedChatChannels]);

  const closeChimeChatUsersModal = useCallback(() => {
    setChimeChannelUserData()
  }, [])

  const updateChannelUsers = useCallback((event, { chimeChannelUsers, originalChannelUsers }) => {
    const addedUsers = chimeChannelUsers.filter(x => !originalChannelUsers.includes(x))
    const removedUsers = originalChannelUsers.filter(x => !chimeChannelUsers.includes(x))
    const channelData = chimeChannelUserData.channelData
    console.log('SUBMIT CHIME CHANNEL USER CHANGE', channelData, originalChannelUsers, chimeChannelUsers, { addedUsers, removedUsers })
    setChimeChannelUserData()
    updateUserRef.current(channelData.ChannelArn, addedUsers, removedUsers)
  }, [chimeChannelUserData])

  const toggleChatPanel = useCallback(() => {
    setShowChat(prev => {
      return { open: !prev.open }
    })
  }, [setShowChat]);

  useEffect(() => {
    if (showChat && showChat.open) setChatNotification(0)
  }, [showChat])

  const showChatNotification = useCallback((newMessages) => {
    if (!(showChat && showChat.open)) setChatNotification(newMessages)
  }, [showChat])

  const getSessionFile = useCallback(async (filename) => {
    const token = oktaAuth.getAccessToken();
    const data = await getSessionFilePromise(session.id, filename, token);
    return data;
  }, [oktaAuth, session])

  const deleteSessionFile = useCallback(async (filename) => {
    const token = oktaAuth.getAccessToken();
    const data = await deleteSessionFilePromise(session.id, filename, token);
    return data;
  }, [oktaAuth, session])

  const getAllSessionFiles = useCallback(async () => {
    const token = oktaAuth.getAccessToken();
    return await getAllSessionFilesPromise(session.id, token);
  }, [oktaAuth, session])

  const uploadSessionFile = useCallback(async (file) => {
    const token = oktaAuth.getAccessToken();
    return await uploadSessionFilePromise(file, session.id, token);
  }, [oktaAuth, session])

  // console.log('messageInfo:', messageInfo);
  return (
    <Root theme={theme}>
      <div className={clsx(classes.content, { [classes.contentShift]: (showChat && showChat.open) })}>
        <div className={classes.page}>
          {loading && <Modal style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open>
            <CircularProgress />
          </Modal>}
          {/* <div className={classes.sessions}> */}
          <>
            <SessionSubscription refresh={update} sessionId={sessionId} onLoaded={sessionLoaded} key={`session${sessionId}subscription`} />
            <GuestsSubscription refresh={guestUpdate} onLoaded={guestsLoaded} sessionId={sessionId} key={`session${sessionId}guestsubscription`} />
            {!session && <LoadingPage />}
            {(session && session.id /*&& (user.fullAdmin || user.assignedSessions.includes(session.id))*/) && <>
              {sessionModalOpen && <SessionModal team={user.team} allowFullEdit={user.accessLevels.includes('session')} allowPostEdit={!!user.fullAdmin} allowVideoEdit={user.accessLevels.includes('video')} data={cloneDeep(sessionInfo)} getProjects={getProjects} getVideos={getVideos} getYoutubeVideo={getYoutubeVideo} getSurveys={getSurveys} getPFMScreenings={getPFMScreenings} getAppointmentTypes={getAppointmentTypes} getCalendars={getCalendars} getZoomUsers={getZoomUsers} getZoomUserMeetings={getZoomUserMeetings} getIVSChannels={getIVSChannels} onSubmit={submitSession} open={sessionModalOpen} handleClose={handleCloseSessionDialog} />}
              <SessionDetailsHeader {...session} getAllSessionFiles={getAllSessionFiles} deleteSessionFile={deleteSessionFile} getSessionFile={getSessionFile} uploadSessionFile={uploadSessionFile} videoIndex={videoIndex || (session.videoIndex || 0)} videoLength={session.contentLength && session.contentLength.length ? session.contentLength[videoIndex || (session.videoIndex || 0)] : session.videoLength} playlistLength={session.contentLength?.length || 0} videoComplete={session.finished || videoComplete} currentView={selectedView} allowComplete={!!user.fullAdmin} allowEdit={user.accessLevels.includes('session')} allowBalance={user.accessLevels.includes('balance')} allowTechCheck={user.accessLevels.includes('tc')} allowLiveDial={user.accessLevels.includes('dial')} allowRewards={!!user.fullAdmin} onEditClick={editSession} onSegmentExportClick={exportSessionSegments} onViewChange={handleViewChange} onOpenFile={handleOpenFile} onOpenLink={handleOpenLink} filesMenuAnchor={filesrAnchorEl} onFilesMenuClose={handleFilesMenuClose} onSessionCompleteClick={openCompleteWarning} linksMenuAnchor={linksrAnchorEl} onLinksMenuClose={handleLinksMenuClose} videoWatermark={user.email} startVideo={sessionStarted} liveStreamStatus={getYoutubeVideo} updateTimeRef={updateTimeRef} videoPlayerRef={playerRef} showChat={showChat} chatOpen={(showChat && showChat.open)} toggleChat={toggleChatPanel} chatNotification={chatNotification} />
              <Divider />
              {/* <Slide direction="right" in={selectedView === 'guests'} mountOnEnter unmountOnExit>
            <div><SessionUsersView sessionId={session.id} guests={guests} emailGuestLink={emailGuestLink} /></div>
          </Slide>
          <Slide direction="left" in={selectedView === 'video'} mountOnEnter unmountOnExit>
            <div><SessionVideoView sessionId={session.id} guests={guests} connections={connections} /></div>
          </Slide> */}
              {guestModalOpen && <GuestModal data={guestSelected} guests={guests} zoomAliases={zoomAliases} sessionStarted={session.started} sessionComplete={session.complete} segments={session.audienceSegments} include2fa={session.include2fa} bypassTechCheck={session.bypassTechCheck} focusGroup={session.type === 'Focus Group'} externalRecruit={!!(session.externalRecruit && session.externalRecruitId)} onSubmit={submitGuest} open={guestModalOpen} handleClose={handleCloseGuestDialog} allowEdit={user.accessLevels.includes('tc')} allowPostEdit={!!user.fullAdmin} getGuestPhoto={getGuestPhoto} />}
              {(selectedView === 'guests' || selectedView === 'email') && <SessionUsersView chatOpen={(showChat && showChat.open)} submitGuest={submitGuest} moveGuests={moveGuests} getProjectSessions={getProjectSessions} handleBulkZoomRegister={handleBulkZoomRegister} hasZoomMeeting={session.hasZoomMeeting} zoomMeetingId={session.zoomMeetingId} multiSelect={selectedView === 'email'} allowCheckIn={user.accessLevels.includes('tc')} allowPii={user.accessLevels.includes('tc') || user.accessLevels.includes('audience')} allowFG={user.accessLevels.includes('balance')} allowIp={!!user.fullAdmin} allowManage={user.accessLevels.includes('audience')} emailLoading={guestSendEmail} checkInLoading={guestCheckIn} approveLoading={guestApprove} waitlistLoading={guestWaitlist} turnAwayLoading={guestTurnAway} zoomLoading={zoomLoading} canceledLoading={guestCanceled} loading={selectedView === 'email' ? batchLoading : guestLoading} sessionId={session.id} sessionStarted={session.started} sessionComplete={session.complete} checkInStarted={session.checkInStarted} segments={session.audienceSegments} bypassTechCheck={session.bypassTechCheck} autoTechCheck={session.autoTechCheck} focusGroup={session.type === 'Focus Group'} externalRecruit={!!(session.externalRecruit && session.externalRecruitId)} guests={guests} editGuest={editGuest} deleteGuest={deleteGuest} banGuest={banGuest} checkInGuest={checkInGuest} techCheckGuest={techCheckGuest} approveGuest={approveGuest} waitlistGuest={waitlistGuest} emailRegistrationLink={emailRegistrationLink} emailGuestLink={emailGuestLink} sendBatchEmails={sendBatchEmails} toggleFocusGroup={toggleGuestFocusGroup} turnAwayGuest={turnAwayGuest} noShowGuest={noShowGuest} canceledGuest={canceledGuest} getGuestPhoto={getGuestPhoto} noShowBatch={noShowBatch} updateBatch={batchUpdate} />}
              {selectedView === 'video' && <SessionVideoView sessionId={session.id} allowPii={user.accessLevels.includes('tc') || user.accessLevels.includes('audience')} allowIp={!!user.fullAdmin} allowControl={user.accessLevels.includes('session')} focusGroup={session.type === 'Focus Group'} surveyPlacement={session.hasSurvey && session.surveyPlacement} hasZoomMeeting={session.hasZoomMeeting} enableGeoRestriction={session.enableGeoRestriction} clientViewers={session.clientDialViewer} guests={guests} connections={vConnections} openChat={openChat} sendGuestMessage={sendGuestMessage} ejectGuest={handleGuestEject} forceRefreshGuest={handleForceRefresh} moveGuestForward={handleMoveForward} moveGuestComplete={handleMoveComplete} uncheckInGuest={uncheckInGuest} uncheckInLoading={guestUncheckIn} resetGuestConnection={handleResetGuestConnection} startVideo={handleStartVideo} currentStartTime={startTime} stopSession={handleStopSession} setLiveStart={handleLiveStart} turnAwayGuest={turnAwayGuest} resetSession={handleResetVideo} multiSection={session.multiSection} playlistLength={session.contentLength?.length || 0} sessionStarted={session.started || sessionStarted} liveStarted={session.liveStartTime || liveStart} sessionStartTime={startTime || session.contentStartTime?.[videoIndex || (session.videoIndex || 0)]} videoIndex={videoIndex || (session.videoIndex || 0)} videoComplete={session.finished || videoComplete} isLiveSession={session.isLiveSession} />}
              {selectedView === 'dial' && <SessionClientView sessionId={session.id} allowPii={user.accessLevels.includes('tc') || user.accessLevels.includes('audience')} allowIp={!!user.fullAdmin} clients={clients} connections={clConnections} uncheckInGuest={uncheckInGuest} uncheckInLoading={guestUncheckIn} disableChat={chatDisabled} openChat={openChat} />}
              {selectedView === 'techcheck' && <SessionTechCheckView sessionId={session.id} guests={guests} connections={tcConnections} editGuest={editGuest} openChat={openChat} sendGuestMessage={sendGuestMessage} startVideo={handleStartVideo} approveGuest={approveGuest} approveLoading={guestApprove} denyGuest={denyGuest} denyLoading={guestDeny} resetGuest={resetGuest} resetLoading={guestReset} waitlistGuest={waitlistGuest} waitlistLoading={guestWaitlist} techCheckNote={session.techCheckNote} />}
              {selectedView === 'balance' && <SessionBalanceView {...session} guests={guests} />}
              {selectedView === 'rewards' && <SessionRewardsView sessionId={session.id} guests={rewardGuests} segments={session.audienceSegments} update={rewardsUpdate} getTangoCustomers={getTangoCustomers} getTangoCatalogs={getTangoCatalogs} getTangoEmailTemplates={getTangoEmailTemplates} submitSessionRewards={submitSessionRewards} submitGuestReward={submitGuestReward} submitRewardsOrder={submitRewardsOrder} resendGuestReward={resendGuestReward} resendLoading={guestRewardResend} />}
            </>}
          </>
          {/* </div> */}
          <StandardDialog
            open={completeWarnOpen}
            title="Complete Session?"
            content="Are you sure you want to mark this session as completed?"
            submitLabel="YES"
            onSubmit={handleCompleteSession}
            cancelLabel="NO"
            onCancel={() => {
              setCompleteWarnOpen(false)
            }}
          />
          {/* <Snackbar
          // key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errorOpen}
          autoHideDuration={hideErrorManually ? null : 6000}
          onClose={handleErrorClose}
          TransitionProps={{
            onExited: handleErrorClear
          }}
          ClickAwayListenerProps={{ onClickAway: () => hideErrorManually ? null : true }}
          // onExited={handleErrorClear}
          message={error}
          action={
            <React.Fragment>
              {/* <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button> * /}
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleErrorClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        /> */}
          {/* <AddFab show={selectedView === 'guests'} onClick={addGuest} /> */}
          {chimeChannelUserData && <ChimeChatUsersModal {...chimeChannelUserData} guests={guests} connections={connections} onSubmit={updateChannelUsers} open={!!chimeChannelUserData} handleClose={closeChimeChatUsersModal} />}
          {(session && session.chimeChatInstance && allowedChatChannels.length) && <ChatDrawer open={(showChat && showChat.open)} setOpen={setShowChat} getChatCredentials={getChatCredentials} chatInstance={session.chimeChatInstance} chatChannels={allowedChatChannels} updateUsers={updateUserRef} messageUser={messageUserRef} onStarted={onChatStarted} onConnectionChange={chatConnectionChange} onManageChannelGuests={manageChannelGuests} onChannelMessage={showChatNotification} />}
          {user.accessLevels.includes('tc') && selectedView === 'guests' && <GuestSpeedDial test={session?.test} show={selectedView === 'guests'} chatOpen={(showChat && showChat.open)} allowUpload={user.accessLevels.includes('audience')} onClickAdd={addGuest} onUploadCSV={handleCSVUpload} onError={handleFileError} />}
          <div className={classes.footer} />
        </div>
      </div>
    </Root>
  );
})

export default withSuspense(SessionManagementPage)