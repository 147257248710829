import React, { useEffect, useRef, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useEventListener } from '../hooks/hooks'
import { IconButton } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  registerIVSQualityPlugin,
  registerIVSTech,
} from 'amazon-ivs-player';

import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

const PREFIX = 'TicktBoxVideoPlayer';


const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  resume: `${PREFIX}-resume`,
  youtube: `${PREFIX}-youtube`,
  player: `${PREFIX}-player`,
  playButton: `${PREFIX}-playButton`,
  muteButton: `${PREFIX}-muteButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.youtube}`]: {
    maxWidth: props => props.aspectRatio ? `${1 / props.aspectRatio * 100}vh` : 0,
    maxHeight: props => props.aspectRatio ? `${props.aspectRatio * 100}vw` : 0,
    width: props => props.aspectRatio ? '100%' : 0,
    height: props => props.aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  [`& .${classes.player} iframe`]: {
    width: '100%',
    height: '100%',
  },
  [`& .${classes.playButton}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    zIndex: 1004
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white',
    zIndex: 1200
  },
});

const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const getVideoAspectRatio = async (videoId) => {
  const res = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`)
  try {
    const { height, width } = await res.json()
    return height / width
  } catch (err) {
    console.log(err)
    return null
  }
}

const IVSVideoPlayer = React.memo(({ videoRef, style, fluid, fill, fullscreen, updateTimeRef, videoId, startVideo, alreadyStarted, onStarted, onMetadataLoaded, onVideoLoaded, onPlay, onVisiblityChange, onFinished, onVideoError, isLive, liveStreamStatus, sessionComplete }) => {
  const [aspectRatio, setAspectRatio] = useState()
  // const [resuming, setResuming] = useState(alreadyStarted)
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)

  const visibilityChangeFn = () => { }

  const playerRef = useRef(null);
  const allowPause = useRef(false)



  useEffect(() => {
    if (startVideo && playerRef.current) playerRef.current.play()
  }, [startVideo])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    console.log('VIDEO - IS SESSION COMPLETE?', sessionComplete)
    if (sessionComplete && playerRef.current) playerRef.current.pause()
  }, [sessionComplete])

  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);

  useEffect(() => {
    // if (!isPlayerSupported) {
    //   console.error("Amazon IVS Player is not supported on this browser.");
    //   return;
    // }

    // Register IVS tech and plugin
    registerIVSTech(videojs, {
      wasmWorker: createAbsolutePath(wasmWorkerPath),
      wasmBinary: createAbsolutePath(wasmBinaryPath),
    });
    registerIVSQualityPlugin(videojs);

    const videoElement = videoRef.current;

    // Initialize the Video.js player with IVS configuration
    playerRef.current = videojs('video-js-live', {
      techOrder: ["AmazonIVS"],
      // controls: true,
      autoplay: true
    }, () => {
      const events = playerRef.current.getIVSEvents();
      const ivsPlayer = playerRef.current.getIVSPlayer();
      ivsPlayer.addEventListener(events.PlayerState.ENDED, () => {
        // console.log('IVS Player has ended')
        if (onFinished) onFinished()
        playerRef.current.ended();

      });
      ivsPlayer.addEventListener(events.PlayerState.PLAYING, () => {
        // console.log('IVS Player is playing')
        if (onStarted) onStarted()
      });
      ivsPlayer.addEventListener(events.PlayerState.IDLE, () => {
        // console.log('IVS Player is paused/idle')
        if (!(playerRef.current.ended() || allowPause.current)) {
          playerRef.current.play()
        } else {
          allowPause.current = false
          playerRef.current.currentTime(0)
        }
      });
      ivsPlayer.addEventListener(events.PlayerEventType.SYNC_TIME_UPDATE, (e) => {
        // console.log('IVS Player TIME_UPDATE', e)
        // playerRef.current.overlay({
        //   content: '',
        //   class: classes.customOverlay,
        //   overlays: [{
        //     align: "center",
        //     content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
        //     start: 'play',
        //     end: 'ended'
        //   }]
        // });
        if (updateTimeRef) updateTimeRef(e)
      });

      playerRef.current.src(videoId)
      if (videoRef) videoRef.current = playerRef.current //to get time data
      return () => {
        ivsPlayer.removeEventListener(events.PlayerState.ENDED, () => { });
        ivsPlayer.removeEventListener(events.PlayerState.PLAYING, () => { });
        ivsPlayer.removeEventListener(events.PlayerState.IDLE, () => { });
        playerRef.current.dispose();
      };
    });

    playerRef.current.enableIVSQualityPlugin();
    // return () => {
    //   // Clean up player on unmount
    //   if (playerRef.current) {
    //     playerRef.current.dispose();
    //   }
    // };
  }, [videoId, sessionComplete, isLive, liveStreamStatus, videoRef, onFinished, onMetadataLoaded, onStarted, onVideoError, alreadyStarted]);

  const toggleMute = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.muted(!muted)
      setMuted(prev => !prev)
    }
  }, [muted, setMuted])

  const togglePlay = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      if (playing) {
        allowPause.current = true
        // playerRef.current.currentTime(playerRef.current.duration())
        playerRef.current.pause()
      } else {
        playerRef.current.play()
      }
      // playing ? playerRef.current.currentTime(playerRef.current.duration()) : playerRef.current.play()
      setPlaying(prev => !prev)
    }
  }, [playing])

  const toggleFullScreen = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.requestFullscreen();
    }
  }, [])

  return (
    // <div className="video-container">
    //   <video
    //     id="video-js-live"
    //     // ref={videoRef}
    //     className="video-js vjs-big-play-centered"
    //     autoPlay
    //   />
    // </div>
    <Root>
    <div style={{ position: 'relative' }}>
        <video
        id="video-js-live"
        // ref={videoRef}
        className="video-js vjs-big-play-centered"
        autoPlay
      />
        {(!isLive && !startVideo && !alreadyStarted) ?
          <IconButton className={classes.playButton} color="inherit" onClick={togglePlay}>
            {playing ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton> : <IconButton className={classes.playButton} color="inherit" onClick={toggleFullScreen}>
            {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
            <FullscreenIcon />
          </IconButton>
        }
        <IconButton className={classes.muteButton} color="inherit" onClick={toggleMute}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </div>
    </Root>
  );
});

export default IVSVideoPlayer;
